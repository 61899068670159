import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
  },
  {
    path: "/donors",
    name: "Donors",
    component: () =>
      import(/* webpackChunkName: "donors" */ "@/views/Donors.vue"),
  },
  {
    path: "/error404",
    name: "Error404",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Error404.vue"),
  },
  {
    path: "/error",
    name: "Error502",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Error502.vue"),
  },
  {
    path: "/donors/profile/:userId",
    name: "DonorsProfile",
    component: () =>
      import(
        /* webpackChunkName: "donorprofile" */ "@/views/DonorsProfile.vue"
      ),
    meta: {
      subPage: "Profile",
      parentPage: "Donors",
    },
  },
  {
    path: "/videos",
    name: "Videos",
    component: () =>
      import(/* webpackChunkName: "videos" */ "@/views/Videos.vue"),
  },
  {
    path: "/videos/add",
    name: "VideosCreate",
    component: () =>
      import(/* webpackChunkName: "videoscreate" */ "@/views/VideosCreate.vue"),
    meta: {
      subPage: "Add Video",
      parentPage: "Target Video",
    },
  },
  {
    path: "/videos/edit/:id",
    name: "VideosEdit",
    component: () =>
      import(/* webpackChunkName: "videoscreate" */ "@/views/VideosCreate.vue"),
    meta: {
      subPage: "Edit",
      parentPage: "Videos",
      isEdit: true,
    },
  },
  {
    path: "/videos/add-slide",
    name: "ComplianceSlideAdd",
    component: () =>
      import(
        /* webpackChunkName: "slidescreate" */ "@/views/VideosSlideCreate.vue"
      ),
    meta: {
      type: "normal", //Case Sensitive
      subPage: "Add Compliance Slide",
      parentPage: "Compliance Slide",
    },
  },
  {
    path: "/videos/add-image",
    name: "ComplianceImageAdd",
    component: () =>
      import(
        /* webpackChunkName: "slidescreate" */ "@/views/VideosSlideCreate.vue"
      ),
    meta: {
      type: "image", //Case Sensitive
      subPage: "Add Compliance Image",
      parentPage: "Compliance Image",
    },
  },
  {
    path: "/videos/edit-image/:id",
    name: "ComplianceImageEdit",
    component: () =>
      import(
        /* webpackChunkName: "slidescreate" */ "@/views/VideosSlideCreate.vue"
      ),
    meta: {
      subPage: "Edit",
      parentPage: "Compliance Slide",
      isEdit: true,
      type: "image", //Case Sensitive
    },
  },
  {
    path: "/videos/edit-slide/:id",
    name: "ComplianceSlideEdit",
    component: () =>
      import(
        /* webpackChunkName: "slidescreate" */ "@/views/VideosSlideCreate.vue"
      ),
    meta: {
      subPage: "Edit",
      parentPage: "Compliance Slide",
      isEdit: true,
      type: "normal", //Case Sensitive
    },
  },
  {
    path: "/demoAd",
    name: "DemoAdVideo",
    component: () =>
      import(/* webpackChunkName: "demoad" */ "@/views/DemoAd.vue"),
    meta: {
      subPage: "Demo Video ",
      parentPage: "Videos",
    },
  },
  // {
  //   path: "/passwords",
  //   name: "Passwords",
  //   component: () =>
  //     import( /* webpackChunkName: "about" */ "@/views/Passwords.vue"),
  // },
  {
    path: "/campaign-template",
    name: "Campaign Template",
    component: () =>
      import(/* webpackChunkName: "layouttemplate" */ "@/views/Content.vue"),
  },
  {
    path: "/getting-started",
    name: "Getting Started",
    component: () =>
      import(
        /* webpackChunkName: "gettingstarted" */ "@/views/GettingStarted.vue"
      ),
  },
  {
    path: "/settings",
    name: "Account Settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/Settings.vue"),
  },
  {
    path: "/administrators",
    name: "Administrators",
    component: () =>
      import(/* webpackChunkName: "admins" */ "@/views/Admins.vue"),
  },
  {
    path: "/demoVideo",
    name: "Demo Video",
    component: () =>
      import(/* webpackChunkName: "demo" */ "@/views/DemoVideo.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "@/views/Error404.vue"
      ),
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () =>
  //     import( /* webpackChunkName: "about" */ "@/views/Login.vue"),
  // },
  // {
  //   path: "/register",
  //   name: "MasterRegistration",
  //   component: () =>
  //     import( /* webpackChunkName: "about" */ "@/views/MasterRegistration.vue"),
  // },
  // {
  //   path: "/forgot",
  //   name: "ForgotPasswordEmail",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "forgot" */
  //       "@/views/ForgotPassword/ForgotPasswordEmail.vue"
  //     ),
  // },
  // {
  //   path: "/forgot/code",
  //   name: "ForgotPasswordCode",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "forgot" */
  //       "@/views/ForgotPassword/ForgotPasswordCode.vue"
  //     ),
  // },
  // {
  //   path: "/forgot/change",
  //   name: "ForgotPasswordChangePassword",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "forgot" */
  //       "@/views/ForgotPassword/ForgotPasswordChangePassword.vue"
  //     ),
  // },
];

const personalPacRouter = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default personalPacRouter;
