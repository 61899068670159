import Vue from "vue";
import App from "./App.vue";
import PPError502 from "./views/Error502.vue";
import GPError502 from "./views-giveproudly/Error502";


// external plugins
import "./plugins/bootstrap-vue";
import "./plugins/fontawesome-vue";
import "./plugins/apexcharts-vue";
import "./plugins/keycloak";
import "./plugins/editor-vue";
import "./plugins/croppa-vue";


import store from "./store";
import personalPacRouter from "./router/personal-pac";
import giveProudlyRouter from "./router/give-proudly";

const router =
  process.env.VUE_APP_NAME === "give-proudly"
    ? giveProudlyRouter
    : personalPacRouter;

const ErrorPage = process.env.VUE_APP_NAME === "give-proudly"
  ? GPError502
  : PPError502;


// let router = require("@/router/giveProudly.js");

Vue.config.productionTip = false;
Vue.prototype.version = window.version;

Vue.$keycloak
  .init({ onLoad: "login-required" })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      new Vue({
        router,
        store,
        render: (h) => h(App),
      }).$mount("#app");
    }

    //Token Refresh
    setInterval(() => {
      Vue.$keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            // console.log("TOKEN REFRESHED", refreshed)
          } else {
            // console.log("TOKEN REFRESHED Valid for", Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
          }
        })
        .catch(() => {
          console.log("Failed to refresh token");
        });
    }, 6000);
  })
  .catch((e) => {
    console.log(e);
    new Vue({
      render: (h) => h(ErrorPage),
    }).$mount("#app");
  });
