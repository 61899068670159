import Vue from "vue";
import Keycloak from "keycloak-js";

// TODO Extraact these config variables to .env 
const initOptions = {
    // url: 'http://localhost:8080',
    // realm: 'myrealm',
    // clientId: 'vueapp'
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
}

const _keycloak = new Keycloak(initOptions);
const Plugin = {
    install: Vue => {
        Vue.$keycloak = _keycloak;
    }
}
Plugin.install = Vue => {
    Vue.$keycloak = _keycloak;
    Object.defineProperties(Vue.prototype, {
        $keycloak: {
            get() {
                return _keycloak;
            },
        },
    });
}


Vue.use(Plugin);

export default Plugin;