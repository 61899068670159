<template>
  <Layout>
    <b-row
      class="d-flex flex-row align-items-center justify-content-end w-50 ml-md-auto body-container"
      no-gutters
    >
      <!-- <b-col cols="12" lg="6" class="d-flex flex-column align-items-center justify-content-center logo-container">
        <b-img src="@/assets/personal-pac-logo-primary.svg" class="logo-img" alt="Personal PAC" fluid-grow></b-img>
      </b-col> -->
      <!-- <b-img src="@/assets/personal-pac-logo-primary.svg" class="bg-img" alt="Personal PAC" fluid-grow></b-img> -->
      <b-col
        cols="12"
        lg="8"
        xl="6"
        class="d-flex flex-column align-items-center justify-content-start justify-content-lg-center px-0 py-3 inner-container"
      >
        <slot></slot>
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
import Layout from "@/layout/Layout";
export default {
  components: {
    Layout,
  },
};
</script>

<style scoped>
.body-container {
  min-height: 600px;
  height: 100vh;

  /* background-image: url("https://storage.googleapis.com/assets.giveproudly.com/bg_login.jpg");
  background-repeat: no-repeat;
  background-size: cover; */

  /* background-position: 100px 5px;  */
}
.body-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url("https://storage.googleapis.com/assets.giveproudly.com/bg_login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotate(180deg);
}
.body-container .bg-img {
  /* height: 200vh; */
  position: absolute;
  min-height: 600px;
}
.body-container .logo-container {
  height: 100vh;
  background-color: var(--primary);
}
.body-container .inner-container {
  height: 100vh;
  background-color: transparent;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .body-container .logo-container {
    height: 25vh;
    overflow: hidden;
  }
  .body-container .inner-container {
    height: 75vh;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .body-container .logo-container {
    height: 20vh;
  }
  .body-container .inner-container {
    height: 80vh;
  }
}
</style>
