import Vue from "vue";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

// variable overrides [https://bootstrap-vue.org/docs/reference/theming]
// import "@/assets/style.scss";

if (process.env.VUE_APP_NAME == "give-proudly") {
  require("@/assets/give-proudly-style.scss");
} else {
  require("@/assets/personal-pac-style.scss");
}

console.log(process.env.VUE_APP_NAME);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
