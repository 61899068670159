import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
import {
  faTachometerAlt,
  faComments,
  faUsers,
  faTrophy,
  faGifts,
  faLanguage,
  faCog,
  faUsersCog,
  faArrowLeft,
  faArrowRight,
  faSignOutAlt,
  faBars,
  faTimes,
  faEye,
  faEyeSlash,
  faCertificate,
  faBan,
  faCheck,
  faTrash,
  faSearch,
  faFilter,
  faPlus,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faPen,
  faStopCircle,
  faEllipsisH,
  faEllipsisV,
  faFileExport,
  faCalendar,
  faExclamationTriangle,
  faCheckCircle,
  faThLarge,
  faPlay,
  faUserShield,
  faLock,
  faLink,
  faPlayCircle,
  faKey,
  faQuestion,
  faNewspaper,
  faTimesCircle,
  faAngleDown,
  faVideo,
  faInfoCircle,
  faAd,
  faPause,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faTachometerAlt,
  faComments,
  faUsers,
  faTrophy,
  faGifts,
  faLanguage,
  faCog,
  faUsersCog,
  faArrowLeft,
  faArrowRight,
  faSignOutAlt,
  faBars,
  faTimes,
  faTimesCircle,
  faEye,
  faEyeSlash,
  faCertificate,
  faBan,
  faCheck,
  faTrash,
  faSearch,
  faFilter,
  faPlus,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faPen,
  faStopCircle,
  faEllipsisH,
  faEllipsisV,
  faFileExport,
  faCalendar,
  faExclamationTriangle,
  faCheckCircle,
  faThLarge,
  faPlay,
  faUserShield,
  faLock,
  faLink,
  faPlayCircle,
  faKey,
  faQuestion,
  faNewspaper,
  faAngleDown,
  faVideo,
  faInfoCircle,
  faAd,
  faPause,
  faClock,
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
