import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    sideMenuIndex: localStorage.getItem("side-menu-index") ? localStorage.getItem("side-menu-index") : 0,
    sideMenuExpanded: false,
    globalCountryCode: null,
  },
  mutations: {
    SET_SIDE_MENU_INDEX(state, payload) {
      state.sideMenuIndex = payload;
    },
    SET_IS_SIDE_MENU_EXPANDED(state, payload) {
      state.sideMenuExpanded = payload;
    },
    SET_GLOBAL_COUNTRY_CODE(state, payload) {
      state.globalCountryCode = payload;
    },
  },
  actions: {
    setSideMenuIndex({ commit }, payload) {
      localStorage.setItem("side-menu-index", payload);
      commit("SET_SIDE_MENU_INDEX", payload);
    },
    setIsSideMenuExpanded({ commit }, payload) {
      commit("SET_IS_SIDE_MENU_EXPANDED", payload);
    },
    setGlobalCountryCode({ commit }, payload) {
      commit("SET_GLOBAL_COUNTRY_CODE", payload);
    },
  },
  getters: {
    getSideMenuIndex: (state) => state.sideMenuIndex,
    getIsSideMenuExpanded: (state) => state.sideMenuExpanded,
    getGlobalCountryCode: (state) => state.globalCountryCode,
  },
});
